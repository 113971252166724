import * as React from "react"
import Layout from "../../components/layout"
import "@fontsource/raleway/300.css"
import Hero from '../../components/hero'
import Header from '../../components/header'
import CharacterGrid from '../../components/charactergrid'
import InBrief from '../../components/inbrief'
import AdBanner from '../../components/adbanner'
import { StaticImage } from 'gatsby-plugin-image'
import { ImageRight, ImageFull } from '../../components/imageelements'
import { SynopsisAct } from '../../components/synposisstyling'
import Bibliography from '../../components/bibliography.js'
import { Helmet } from "react-helmet"
import AudioSet from '../../components/audioset'
import { AdContainer } from '../../components/adstyling'
import { FunFactsHeader } from '../../components/funfactsstyling'
import { MainBodySection } from '../../components/generalstyling'
import VideoPlayer from '../../components/videoplayer'
import BlockQuote from '../../components/blockquote'

const characters = [
  {name: 'Flora', voice: 'Soprano', desc: 'Miles\'s younger sister, though usually sung by an adult which means she\'s often played older.'},
  {name: 'Governess', voice: 'Soprano', desc: 'The opera\'s main protagonist, never given a name. A 20-year-old woman, intelligent but volatile. She views herself as a heroine even if we\'re not so sure.'},
  {name: 'Miles', voice: 'Treble', desc: 'A fairly creepy boy of 10. One of the two children the Governess has been sent to care for at Bly.'},
  {name: 'Miss Jessel', voice: 'Soprano', desc: 'The last governess, deceased.'},
  {name: 'Mrs. Grose', voice: 'Soprano', desc: 'The housekeeper at Bly. The Governess\'s only adult companion and as a result: her confidant.'},
  {name: 'Peter Quint', voice: 'Tenor', desc: 'Formerly a valet at Bly, deceased.'},
  {name: 'Prologue', voice: 'Tenor', desc: 'A narrator of sorts who opens the evening. Usually sung by the same singer as Peter Quint.'},
];

const inbrief = {name: 'The Turn of the Screw', composer: "Benjamin Britten", librettist: "Myfanwy Piper", language: "English", date: 'September 14th, 1954', acts: "Two", musiclength: "One hour, forty minutes"};

const bibliography = [
  {name: 'The Operas of Benjamin Britten: Expression and Evasion', writer: 'Claire Seymour', url: 'https://amzn.to/2WipAHk'},
  {name: 'The Music of Benjamin Britten', writer: 'Peter Evans', url: 'https://amzn.to/3yec6tv'},
  {name: 'Benjamin Britten', writer: 'Michael Oliver', url: 'https://amzn.to/3ki4tgF'},
  {name: 'Benjamin Britten: A Life for Music', writer: 'Neil Powell', url: 'https://amzn.to/3879eUF'},]

const YeatsQuote = {content: 'The ceremony of innocence is drowned.', quotee: 'W. B. Yeats. The Second Coming'}
const GrossQuote = {content: 'He liked them pretty, I can tell you, Miss, and he had his will morning and night.', quotee: 'Mrs. Gross'}
const MilesQuote = {content: 'You see, I am bad, aren\'t I?', quotee: 'Miles'}
const GovernessQuote = {content: 'What have we done between us?', quotee: 'The Governess'}
const BrittenQuote = {content: 'Listen to wireless … a wonderful, impressive but terribly eerie & scarey play \'The Turn of the Screw\' by Henry James.', quotee: 'Britten in his Diary, 1st June 193'}
const GovernessQuintQuote = {content: 'Whom do you mean by \'he\'?', quotee: 'The Governess'}
const MilesQuintQuote = {content: 'Peter Quint — you devil!', quotee: 'Miles'}


// markup
const ScrewPage = () => {
  return (
    <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>The Turn of the Screw | The Opera 101</title>
          <html lang="en-us" />
          <link rel="canonical" href="https://theopera101.com/operas/screw" />
          <meta name="description" content="A guide to Benjamin Britten's intense opera The Turn of the Screw. Including Synopsis, Music & Arias, Fun Facts, Running Time and much more!" />
        </Helmet>
        <Hero title="The Turn of the Screw" subtitle="An Opera by Benjamin Britten"/>
        <MainBodySection>

          <BlockQuote quote={YeatsQuote}/>

          <p><i>The Turn of the Screw</i> is a disturbing work, a novelty in the opera world for being something of a horror story with genuine chills. Based on Henry James's novel of the same name, it's an old fashioned ghost story that touches on issues of a very contemporary nature.</p>

          
          <div id="ezoic-pub-ad-placeholder-102"> </div>

          <ImageFull>
            <StaticImage src="../../images/operas/screw/oldmansion.jpg" alt="The Haunted House by William Miller after Birket Foster"/>
            <figcaption>The Haunted House by William Miller after Birket Foster</figcaption>
          </ImageFull>

          <p>Musically, it is one of Britten's most interesting. He divided the narrative into a prologue and 16 relatively episodic scenes. However, he connected them with variations of the "Screw" theme, built around the twelve-note row. The sequence ascends in the first Act and descends in the second.</p>

          <ImageFull>
            <StaticImage src="../../images/operas/screw/screwtheme.png" alt="The notes of the screw theme"/>
            <figcaption>The notes of the screw theme</figcaption>
          </ImageFull>

          <p>The prologue begins with just a piano accompaniment, and the ensemble is a mere 13 players, yet the soundworld evoked feels far more substantial.</p>
          
          <Header title="In Brief"/>

          <InBrief content={inbrief}/>

          </MainBodySection>

  
          <div id="ezoic-pub-ad-placeholder-103"> </div>


            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <Header title="Characters"/>

            <CharacterGrid rows={characters}/>

            <Header title="Synopsis"/>

            <SynopsisAct>Prologue - Running Time: 10 mins</SynopsisAct>

            <p>A male figure tells the audience of "a curious story, written in faded ink": a Governess who cared for two young children at Bly. She was hired by their guardian, an uncle living in London with no interest in caring for them himself. He instructed her to follow three rules: never write to him about the children, never ask about the history of Bly house and never abandon the children.</p>

            <VideoPlayer src="https://www.youtube.com/embed/nBnsvppTJ7o?autoplay=1" id="nBnsvppTJ7o" title="Richard Greager sings the prologue"/>

            <SynopsisAct>Act I  - Running Time: 45 mins</SynopsisAct>

            <h4>Theme - The Journey</h4>

            <p>The Governess travels to Bly</p>

            <h4>Variation I - The Welcome</h4>

            <p>The Governess is nervous about her new position but is warmly greeted by Mrs Grose, the housekeeper, and Flora and Miles, the two children to be in her charge. She feels a strange connection to Miles but is led off by Mrs Grose on a tour of the grounds around Bly.</p>

            <h4>Variation II - The Letter</h4>

            <p>Her fears depart her, but she is soon brought back to earth when a letter from Miles's school arrives informing her that Miles has been expelled. No reason is given, and she is convinced Miles is too innocent to have been expelled, and it must be a mistake. She decides to ignore the letter.</p>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-104"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <h4>Variation III - The Tower</h4>

            <p>Wandering the grounds one evening, the Governess sees a strange man on the tower. She doesn't recognise him and is unsettled by the whole experience.</p>

            <VideoPlayer src="https://www.youtube.com/embed/mlyCj99fZ4k?start=19&autoplay=1" id="mlyCj99fZ4k" title="Sara Hershkowitz sing's 'How beautiful it is', otherwise known as the 'Governess's Aria'"/>

            <h4>Variation IV - The Window</h4>

            <p>The children play indoors; the Governess watching over them spots the same unknown figure at the window. She is concerned and describing him to Mrs Grose learns that he appears to be Peter Quint, the former valet. Mrs Grose explains that Quint had an illicit relationship with the last Governess, Miss Jessel, specifically that he "made free" with her. They have both since died: Jessel away from the house, Quint falling on an icy road. Terrified, the Governess fears he has returned for Miles and swears to protect the children.</p>

            <BlockQuote quote={GrossQuote}/>

            <h4>Variation V - The Lesson</h4>

            <p>Miles is reciting Latin with help from Flora and the Governess. Praising him, the Governess asks if he knows any other rhymes. Miles then produces quite one of the creepiest arias in all of opera 'Malo'</p>

            <AudioSet audiosrc="/audio/screw/malo.mp3" desc="David Hemmings sings Malo"/>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-105"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <h4>Variation VI - The Lake</h4>

            <p>By the side of the lake at Bly, the Governess sits reading whilst Flora sings to her doll. Across the lake, the Governess catches sight of a woman all in black, who disappears as mysteriously as she appeared. The Governess believes Miss Jessel has returned as well, and as Flora did not see her, she becomes convinced that the children are lost.</p>

            <h4>Variation VII - At Night</h4>

            <p>Quint calls out to Miles, tempting him from his bed with exotic and desirable visions. Miles is captivated. Miss Jessel appears, attracting Flora; she laments her fate begging Flora to comfort her. Together they try, successfully, to convince the children to find them. Mrs Grose and the Governess enter, terrified by the children's apparent vanishing. The Governess scolds Miles.</p>

            <BlockQuote quote={MilesQuote}/>

            <SynopsisAct>Act II - Running Time: 50 mins</SynopsisAct>

            <h4>Variation VIII - Colloquy and Soliloquy</h4>

            <p>Quint and Jessel flesh out their motives and backstory. They argue over their past and declare their determination to capture Miles and Flora. Separately the Governess despairs at the evil she feels, unable to decide how to act.</p>

            <h4>Variation IX - The Bells</h4>

            <p>The family head to church, the children singing a gently mocking variation of the 'Benedicite'. Mrs Grose remains unaware of the subtext thinks it sweet until the Governess informs her of the children's bizarre behaviour. Mrs Grose suggests the Governess write to the guardian, which she unhappily declines. Miles creepily tells the Governess of his awareness of the Ghosts leading her to contemplate fleeing...</p>

            <ImageFull>
              <StaticImage src="../../images/operas/screw/screw_floraAndMiles_nyco.jpg" alt="Flora (Lauren Worsham) and Miles (Benjamin P. Wenzelberg) on their way to Church"/>
              <figcaption>Flora (Lauren Worsham) and Miles (Benjamin P. Wenzelberg) on their way to Church &copy; Richard Termine/NYCO</figcaption>
            </ImageFull>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-106"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <h4>Variation X - Miss Jessel</h4>

            <p>Returning to the house, the Governess finds Miss Jessel in the schoolroom bemoaning her fate. Confronting her - Jessel vanishes. Believing the ghosts might not yet have the upper hand, the Governess writes to the guardian.</p>

            <h4>Variation XI - The Bedroom</h4>

            <p>Later that night, the Governess goes to Miles's bedroom, coaxing him to tell her about what happened at his school. She tells him of the letter to his guardian, but he barely responds as the voice of Quint intervenes, she leaves despondent.</p>

            <h4>Variation XII - Quint</h4>

            <p>Seduced by Quint, Miles steals the letter from the schoolroom.</p>

            <h4>Variation XIII - The Piano</h4>

            <p>Miles is showing off at the piano. Flora sings, lulling Mrs Grose to slip before slipping out. Noticing Flora's absence, the Governess panicked awakens Mrs Grose. They hurry off to find her while Miles continues at the piano.</p>

            <h4>Variation XIV - Flora</h4>

            <p>They find Flora by the lake. Mrs Grose tries to comfort her, but the Governess seeing Miss Jessel, tries aggressively to make Flora admit that she too can see Jessel. Flora madly accuses the Governess of cruelty and leaves with Mrs Grose. The Governess realises she has lost Flora.</p>

            <ImageFull>
              <StaticImage src="../../images/operas/screw/screw_shout_eno_barda.png" alt="Flora (Nazan Fikret) shouts at The Governess (Rebecca Evans)"/>
              <figcaption>Flora (Nazan Fikret) shouts at The Governess (Rebecca Evans) &copy; Clive Barda/ENO</figcaption>
            </ImageFull>

            <h4>Variation XV - Miles</h4>

            <p>The next morning, Mrs Grose informs the Governess that having spent the night with Flora, she is convinced that evil has possessed Flora. They agree that Mrs Grose will take Flora to London, leaving Miles with the Governess. Mrs Grose also reveals that the letter was never sent and must have been stolen by Miles.</p>

            <p>Alone, the Governess presses Miles to confess to stealing the letter, whilst Quint appears to savagely demand Miles not betray him. Miles admits to stealing the letter but will not name Quint as the person who made him do it. Finally, he cries out, 'Peter Quint -- you devil' and dies. Quint disappears. The Governess weeps, holding the dead Miles.</p>

            <BlockQuote quote={GovernessQuote}/>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-107"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <Header title="Where & When"/>

            <p><i>Screw</i> takes place entirely at Bly: a large, imposing country mansion and estate in Essex, England. It's a period piece set around the 1840s. The atmospheric setting strongly imitates the narrative. Britten almost called his opera: "The Tower and the Lake" (a title that also emphasises the male/female sexuality).</p>

            <ImageRight>
              <StaticImage src="../../images/operas/screw/screw_pupils_and_governess.jpg" alt="A Victorian governess teaching"/>
              <figcaption>A Victorian governess teaching</figcaption>
            </ImageRight>

            <p>The period is important because some plot elements might seem odd to modern audiences. To begin with, the strange, absent guardian was not altogether unusual even amongst more loving families. The industrial revolution was driving much of the wealth away from rural areas towards major cities. The breadwinner (always the man) might well work in London, leaving his country estate in the hands of others, and whilst transportation was fast improving, regular commuting was not yet feasible.</p>

            <p>Governesses were also a curious species in the Victorian era. Both servant and lady, in a strongly hierarchical society they were an anomaly. The Governess was considered a moral conservator of sorts, bound to bring up children as good and proper. Our Governess seems to see herself in this image. Conversely, Quint as the Valet dresses much like his master but is entirely a servant. Yet Quint has his power, using his gender to seduce Miss Jessel, the last Governess, corrupting her in the eyes of society.</p>

            <p>James was far from the only one to use this social limbo that Governesses occupied in Victorian life for fertile drama (often with transgressive romantic themes). Think Thackeray's <i>Vanity Fair</i>, Brontë's <i>Jane Eyre</i> and Austen's <i>Emma</i> amongst many others.</p>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-108"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <Header title="History"/>

            <BlockQuote quote={BrittenQuote}/>

            <p><i>Screw</i> was Britten's final chamber opera. It was commissioned in 1952 for the Venice Biennale. Myfanwy Piper had years earlier suggested James's <i>The Turn of the Screw</i> as a potential subject for a television opera, and encouraged by Peter Pears, Britten asked her to put together an outline for how they might adapt it into an opera.</p>

            <p>Simultaneously, he was working on Gloriana, which had been commissioned for the upcoming coronation of Queen Elizabeth II. As a result, it wasn't until autumn of 1953 that serious work could begin. However, Britten was suffering badly from acute bursitis in his right shoulder and could barely work. Piper began sending draft copies of the libretto in early 1954, and by this time, Britten was reduced to writing letters to her with his left hand.</p>

            <p>He had minor surgery in March, and with the opera's premiere set for September, the schedule Britten was faced with was extremely tight. He wrote the score in a little over four months, from the end of March to August 1954. The premiere was so close that Imogen Holst, Britten's amanuensis, would make a vocal score of each scene as Britten completed them so that the singers could begin preparing whilst the opera was still being written.</p>

            <ImageRight>
              <StaticImage src="../../images/operas/screw/screw_hemmings.jpg" alt="David Hemmings as Miles"/>
              <figcaption>David Hemmings as Miles</figcaption>
            </ImageRight>

            <p>Britten wanted children to play the children but found it impossible to cast a suitable girl for the role of Flora, so the part was given to an adult, Olive Dyer. On the other hand, Miles went to a 12-year-old David Hemmings (who went on to be an extremely successful actor). He would form a strong relationship with Britten - whose relationships with young boys has been the subject of much discussion - at least until his voice broke in 1956 during a performance of <i>Screw</i> in Paris.</p>

            <p> They rehearsed the opera in England with the entire team travelling to Venice for the premiere. The day of the premiere was a stressful one. The Italian stage crew threatened to go on strike, and then, with the audience seated and ready to go, the performance had to be delayed because it was being transmitted live on the radio and an earlier broadcast had run over.</p>

            <p>The reception, from a huge mix of the international press, was fairly positive and fascinatingly a French newspaper referred to 'the composer's customary intense preoccupation with homosexual love', which is very possibly the first unambiguous reference to Britten and homosexuality in the press.</p>

            <p>The same team would present the opera at Sadler's Wells, and it was swiftly recorded (partly because of fears Hemmings's voice would break) by Decca becoming the first complete record of a Britten opera. The opera would receive performances around the world over the next few years and has been a reasonably solid part of the repertory ever since.</p>

          </MainBodySection>

            <div id="ezoic-pub-ad-placeholder-109"> </div>
  
            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <Header title="Fun Facts"/>

            <FunFactsHeader>You Devil</FunFactsHeader>

            <BlockQuote quote={GovernessQuintQuote}/>
            <BlockQuote quote={MilesQuintQuote}/>

            <p>Rarely have dashes been quite so picked over. To whom does Miles actually refer, Peter Quint or the Governess? If you lean towards believing Miles is genuinely possessed, then it is probably Quint. However, if you're more toward the Governess being insane, he could well be suggesting that it is the Governess that has led him to his demise. Who is the true villain? In the original novel, there is, of course, no easy answer. We have nothing to go on but the text. Britten and Piper decided to leave it just as ambiguous. However, the practicalities of the stage mean any production has to make something of a decision. We've seen it go both ways!</p>

            <FunFactsHeader>Copyright</FunFactsHeader>

            <p>Getting permission to use Henry James's novella proved difficult, a matter not helped by the fact Britten had written most of the opera before even asking, something that may have perturbed the James Estate. Curiously part of the problem stemmed from the very thing that had drawn Britten to the work, the sexual ambiguity, James's family still deeply concerned with the rumours around his sexuality. After some years of back and forth, and the granting of limited rights solely for the Venice performances, Britten's solicitor cunningly worked out that if the opera was based not on the novella but on the serialization that had been earlier published (and it was impossible to prove it wasn't!), James's Estate had no control over the work.</p>

            <FunFactsHeader>James in Opera</FunFactsHeader>

            <p>Henry James's work has found a considerable afterlife in operatic treatments. Britten adapted not just <i>Screw</i> but later also <i>Owen Wingrave</i> (1971). James's <i>Washington Square</i> has been adapted three times by Jean-Michel Damase (1974), Thomas Pasatieri (1976) and Donald Hollier (1988) and his "The Aspern Papers" twice by Philip Hagemann and Dominick Argento (both 1988). Pretty good going for an author who once turned down the opportunity to meet Richard Wagner, in April 1880 when both were in Posilipo, Italy, on the grounds that he didn't speak German (he had perhaps more substantial reasons he didn't elucidate).</p>

            <FunFactsHeader>Dirty Latin</FunFactsHeader>

            <p>One can read too much into anything, and perhaps <a href="http://www.theguardian.com/education/2002/jan/05/arts.highereducation">Valentine Cunningham does in her Guardian essay</a>, but it's hard to avoid that the Latin that Piper and Britten inserted into the text (none of it originating in James' novella) has secondary meanings. Sexual slang, mostly for male body parts, can be found throughout the childrens' Latin lesson. The strange benedicite sung by Miles is riddled with innuendo, blasphemous by any standards and indicative of the wider ambiguous sexuality of Miles's relationships with the adults in his life.</p>

            <div id="ezoic-pub-ad-placeholder-110"> </div>

            <FunFactsHeader>Broadcast</FunFactsHeader>

            <p>In 1959, the opera was shown as the first full-length opera broadcast on British independent television. They showed the two acts on different nights, Act I on the 25th of October and Act II on the 28th. If a copy of this still exists, we haven't been able to find it. This would not be the last television adaptation. The BBC produced an excellent version in 1982 that is rather more accessible.</p>

            <FunFactsHeader>Adaptations</FunFactsHeader>

            <p>The Turn of the Screw has been adapted and referenced across so many works of art since its publication we couldn't nearly mention them all here. One of the earliest adaptations was "The Innocents" a 1950 Broadway play that later became a movie. More recently, "The Others" starring Nicole Kidman took another stab at reinventing similar themes, with a rather cunning twist added. The rather less substantial "Insidious 2" features a child reading a copy of the novel, and somewhat satirically, a ghost later unscrews a chandelier. There have been endless television adaptations and perhaps most bizarrely a Star Trek episode entitled <a href="http://en.memory-alpha.wikia.com/wiki/Sub_Rosa_(episode)">"Sub Rosa"</a> featuring a Ned Quint and a Jessel Howard</p>

            <FunFactsHeader>Popularity</FunFactsHeader>

            <p>Britten's <i>The Turn of the Screw</i> had a successful premiere and was fairly quickly performed around the world. It's popularity ever since is easy to underestimate: it may come as some surprise that from from 2009 to 2014 <i>Screw</i> was <a href="http://operabase.com/top.cgi?lang=en&break=0&show=opera&no=100&nat=">the second most performed opera</a> in English (only <i>Dido and Aeneas</i> was performed more).</p>

            <Header title="Bibliography"/>

            <Bibliography content={bibliography}/>

        </MainBodySection>
    </Layout>
  )
}

export default ScrewPage
